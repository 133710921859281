<template>
  <b-container class="m-0 p-0">
    <b-row>
      <b-col cols="4">
        <h4 class="subtitle-form">{{ $t("inputStock.details.seedDensity") }}</h4>
      </b-col>
      <b-col cols="8" class="d-flex align-items-start">
        <NumericInput
          inputRef="input"
          :value="currentEditedEntry.seedDensity || currentEntry.seedDensity"
          @input="updateDensity"
          :numberOfDigits="1"
          class="mr-2"
        />
        <b-select
          size="sm"
          :options="inputFormByPtype.seedDensityUnits"
          text-field="unit"
          value-field="id"
          :value="currentEditedEntry.seedDensityUnit || currentEntry.seedDensityUnit"
          @input="updateUnit"
          :required="densityFlag ? true : false"
        ></b-select>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import { mapGetters } from "vuex";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin],
  data(){
    return {
      densityFlag: null,
    }
  },
  methods: {
    updateDensity(value) {
      this.$store.dispatch(this.storeEditAction, {
        seedDensity: value,
      });
      this.densityFlag = true;
    },
    updateUnit(value) {
      this.$store.dispatch(this.storeEditAction, {
        seedDensityUnit: value,
      });
    },
  },
  computed: {
    ...mapGetters(["inputFormByPtype"]),
  },
  components:{
    NumericInput
  }
};
</script>

<style lang="scss" scoped></style>
